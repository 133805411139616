<template lang="pug">
  div
</template>
<script>
export default {
  created () {
    if (!this.$auth.loading) {
      this.checkUserAuthentication()
    }

    this.$auth.$watch('loading', (loading) => {
      if (!loading) {
        this.checkUserAuthentication()
      }
    })
  },
  methods: {
    checkUserAuthentication () {
      if (!this.$auth.isAuthenticated) {
        this.$auth.loginWithRedirect({ appState: { targetUrl: this.$route.query.redirect } })
      } else {
        this.$store.dispatch('user/activeOrganizationCheckForAuth0User')
          .then(auth0CheckResp => {
            this.$router.push('/campaigns/all')
          })
          .catch(e => {
            this.$router.push({ name: 'Access Denied' })
          })
      }
    }
  }
}
</script>
